export const CATEGORY_BY_DEFAULT = 'premium';
export const EVENT_NAME_BY_DEFAULT = 'microfunnel';
export const ACTIONS: GA4InteractionTypes = {
    onload: 'onload',
    click: 'click',
};

export type InteractionType = 'onload' | 'click';

export interface GA4EventParams {
    action?: string;
    campaign_name?: string;
    discount_type?: number;
    event_name?: string;
    interaction_type?: InteractionType;
    location?: string;
    product_price?: string;
    resource_id?: string;
    file_id?: string;
    resource_type?: string;
    type?: string;
    license?: string;
}

export interface BasicEvent {
    action?: string;
    category?: string;
    label: string;
    noninteraction?: boolean;
}

export interface GA4InteractionTypes {
    [name: string]: InteractionType;
}

export interface EventConfig {
    applyClickEvent?: boolean;
    applyOnloadEvent?: boolean;
    element?: string;
    ga4Options?: { [name: string]: any };
    ignoreDefaultParams?: boolean;
    microfunnel?: BasicEvent;
    replaceCampaignParams?: boolean;
}

export interface ManualEvent {
    category?: string;
    description: string;
    from_view?: string;
    element?: string;
    label?: string;
    options: { [name: string]: any };
}

export type GA4AutomaticEvents = {
    [key in GA4AutomaticNameEvents]: EventConfig;
};

export type GA4ManualEvents = {
    [key in GA4ManualNameEvents]: EventConfig;
};

export type GA4AutomaticNameEvents =
    | 'modalAttributionBannerAfterDownload'
    | 'topMenuPrices'
    | 'bannerAutopromoFilterByPremium'
    | 'bannerAutopromoHeaderRegistered'
    | 'downloadLimitViewGoPremium'
    | 'footerPricing'
    | 'landingPaidPremiumButton'
    | 'mockupBakerDownloadMacFromLanding'
    | 'mockupBakerDownloadWindowsFromLanding'
    | 'modalAttributionBanner'
    | 'authenticPhotosCategoryLanding';

export type GA4ManualNameEvents =
    | 'afterLoginEvents'
    | 'autopromoAbandonedCart'
    | 'autopromoBanner'
    | 'cancellationForm'
    | 'campaignBanners'
    | 'categoryLandingAutopromo'
    | 'clickAuthorInHome'
    | 'collections'
    | 'detailGoPremiumDownloadOptions'
    | 'detailGoPremiumSidebar'
    | 'downloadButtonsModels'
    | 'downloadButtonsMonthly'
    | 'downloadLimitCoupon'
    | 'dropdownFilter'
    | 'editWepikButton'
    | 'followAuthor'
    | 'homeAnonymousBanner'
    | 'homeCategories'
    | 'homeLoggedBannerMicrofunnel'
    | 'homeSections'
    | 'linkFaqsMonthly'
    | 'menuUser'
    | 'mockupBakerAlert'
    | 'modalAttributionBannerAfterDownloadOnload'
    | 'modalAttributionBannerOnload'
    | 'popularSearchesTags'
    | 'popupVideoLaunch'
    | 'pricingTeamsForm'
    | 'pricingTeamsFormSuccess'
    | 'searchLandings'
    | 'sponsor'
    | 'tagRemoveAds'
    | 'tagsThumbnails'
    | 'searchImage'
    | 'searchImageModal'
    | 'searchImageSelection'
    | 'searchImageError'
    | 'mockupsBannerClick'
    | 'advancedSearchModal';
